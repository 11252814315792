<template>
    <div>
        <v-toolbar
            flat
            dense
            dark
            color="primary"
        >
            <v-toolbar-title>
                Movimientos de medicamentos y material
            </v-toolbar-title>
            <v-spacer/>
        </v-toolbar>
        <v-row>
            <v-col cols="12" md="3">
                <select-fechas
                    :futuro="false"
                    :fechasTodas.sync="fechas"
                />

                <div class="d-flex justify-space-around">
                    <v-checkbox
                        label="Entradas"
                        v-model="entradas"
                        dense
                    />
                    <v-checkbox
                        label="Salidas"
                        v-model="salidas"
                        dense
                    />
                </div>
                <select-med
                    :medicamento.sync="medicamento"
                />
                <v-text-field
                    label="Lote"
                    v-model="params.lote"
                    rounded
                    filled
                    dense
                    clearable
                />
                <v-text-field
                    label="Paciente"
                    v-model="params.paciente"
                    type="number"
                    rounded
                    filled
                    dense
                    clearable
                />


                <v-btn @click="list()" class="ma-3">
                    Filtrar
                </v-btn>


            </v-col>
            <v-col cols="12" md="9">
                <v-sheet elevation="2" class="ma-2 pa-2">
                    ({{ medicamento.clave }}) {{ medicamento.concepto}}<br/>
                    <b>Especificación:</b> <span v-html="medicamento.especificacion"></span><br/>
                    <b>Última entrada:</b> {{ medicamento.ultimaEntrada }} <b>Últma salida:</b> {{ medicamento.ultimaSalida }}<br/>
                    <b>Existencia actual: </b>{{ medicamento.existenciaActual }}
                </v-sheet>
                <v-data-table
                    :headers="headers"
                    :items="datos"
                    disable-sort
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50]
                    }"
                    item-key="id"
                    :loading="loadingTabla"
                    :server-items-length='total'
                    :options.sync="optionsTable"
                >

                    <template v-slot:[`item.cantidadEntrada`]="{ item }">
                        <v-icon small color="blue" v-if="item.cantidadEntrada">mdi-login</v-icon>
                        {{ item.cantidadEntrada }}
                    </template>

                    <template v-slot:[`item.cantidadSalida`]="{ item }">
                        {{ item.cantidadSalida }}
                        <v-icon small color="orange" v-if="item.cantidadSalida">mdi-logout</v-icon>
                    </template>

                    <template v-slot:[`item.cad`]="{ item }">
                        {{ moment(item.cad).format("MM-YYYY") }}
                    </template>
                    <template v-slot:[`item.deta`]="{ item }">
                        <v-btn icon @click="detalles(item)"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                </v-data-table>

            </v-col>
        </v-row>

    </div>
</template>

<script>
export default {
    mounted(){
        this.fechas=[
            this.moment(new Date()).format("YYYY-MM-DD"),
            this.moment(new Date()).format("YYYY-MM-DD"),
        ]
    },
    components:{
        'select-fechas':()=>import('@/components/com/DatePickerMultiple'),
        'select-med':()=>import('@/components/com/SelectMedicamentos')
    },
    data:()=>({
        headers: [
            {text: 'Ent', value: 'cantidadEntrada', sortable:false, width: "5%"},
            {text: 'Sal', value: 'cantidadSalida', sortable:false, width: "5%"},
            {text: 'Lote', value: 'lote', sortable:false, align:"center"},
            {text: 'Caducidad', value: 'cad', sortable:false, align:"center"},
            {text: 'Folio', value: 'folio', sortable:false, align:"center"},
            {text: 'Paciente', value: 'paciente', sortable:false, align:"center"},
            {text: 'Tipo', value: 'tipo', sortable:false, align:"center"},
            {text: 'Origen/destino', value: 'origendestino', sortable:false, align:"center"},
            {text: 'Por', value: 'usuario', sortable:false, align:"center"},
            {text: '', value: 'deta', sortable:false, align:"center"},
        ],
        params:{},
        entradas:true,
        salidas:true,
        query:null,
        optionsTable:{},
        loadingTabla:false,
        datos:[],
        total:0,
        fechas:[],
        medicamento:{},
    }),
    methods:{
        detalles(itm){
            this.$swal.fire({
                icon: 'info',
                title: 'Observaciones',
                text: itm.observaciones,
            })
        },

        async list(){
            this.params.medicamento = this.medicamento.id
            this.params.fechaMovDe = this.moment(this.fechas[0]).format('DD/MM/YYYY')
            this.params.fechaMovA = this.moment(this.fechas[1]).format('DD/MM/YYYY')
            if(this.entradas){
                this.params.entradas=1
            }else{
                this.params.entradas=0
            }

            if(this.salidas){
                this.params.salidas=1
            }else{
                this.params.salidas=0
            }
            //this.params.salidas=1
            this.params.start = (this.optionsTable.page-1 )*this.optionsTable.itemsPerPage,
            this.params.limit = this.optionsTable.itemsPerPage
            //this.params.query = this.query
            this.params.tipo = this.tipo
            try{
                const req = await this.$http({
                url:'/comi/listMovimientoClave',
                method:'GET',
                params:this.params,
            })

            this.datos=req.data.root
            this.total=req.data.total
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        nuevo(){
            console.log("Nuevo")
        },

    },
    watch:{
        optionsTable: {
            handler () {
                this.list()
            },
            deep: true,
        },
        /*
        query(val){
            if (val && !this.loadingTabla && (val.length>=3 || val.length==0)){
                this.optionsTable.page = 1
                this.list()
            }
            if (!val || val.length==0){
                this.optionsTable.page = 1
                this.list()
            }
        },
        */
    }

}
</script>

<style>

</style>